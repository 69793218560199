import Subscribe from '../components/subscribe';
import Smart from '../assets/images/smart.svg'
import Financial from '../assets/images/insight.svg'
import Seamless from '../assets/images/exploration.svg'
import Pitch from '../assets/images/investor.svg'
import Tracking from '../assets/images/tracking.png'
import Marketing from '../assets/images/marketing.png'
import Connect from '../assets/images/abbby.svg'
import Explore from '../assets/images/explore.png'
import Check from '../assets/images/check.png'
import Dots from '../assets/images/dots.png'
import Main from '../assets/bg-image/change-one.png'
import Main2 from '../assets/bg-image/change-two.png'
import Main3 from '../assets/bg-image/change-three.png'
import '../input.css'
import { useEffect, useState } from 'react';
import axios from 'axios';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import { useNavigate, useParams } from 'react-router-dom';
import ReactGA from "react-ga4";
import first_arrow from '../assets/images/right-arrow.svg'
import second_arrow from '../assets/images/left-arrow.svg'
import third_arrow from '../assets/images/bottom-left.svg'
import fourth_arrow from '../assets/images/bottom-right.svg'
import middle_image from '../assets/images/middle_image.svg'
import other1 from '../assets/images/group-1.svg';
import other2 from '../assets/images/group-2.svg';
import other3 from '../assets/images/group-3.svg';
import other4  from '../assets/images/group-4.svg';
import { motion } from "framer-motion";
import BackgroundLoader from '../components/builderLoading';

const Home = () => {
    ReactGA.initialize("G-125ZTWLY25");
    ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname,
        title: "Home"
    });

    const navigate = useNavigate();
    const { referralCode } = useParams();
    const [code, setCode] = useState(null);

    useEffect(() => {
        if (referralCode) {
            localStorage.setItem('referralCode', referralCode);
            setCode(referralCode);
        } else {
            const storedCode = localStorage.getItem('referralCode');
            if (storedCode) {
                setCode(storedCode);
            }
        }
    }, [referralCode]);

    const images = [
        Main,
        Main2,
        Main3,
    ];

    const imagestwo = [
        other1,
        other2,
        other3,
        // other4
    ];
    const [currentImage, setCurrentImage] = useState(0);
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [popupVisible, setPopupVisible] = useState(false);

    // const handleButtonClick = () => {
    //     setPopupVisible(!popupVisible);
    // };

    const handleButtonClick = () => {
        navigate('/pricing')
    };


    useEffect(() => {
        axios.get('https://ipinfo.io?token=aee064e2cc5a04')
            .then(response => {
                setData(response.data);
                // console.log(data?.country)
                setLoading(false);
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
            });

        const interval = setInterval(() => {
            setCurrentImage((currentImage + 1) % images.length);
        }, 3000); // 3 seconds
        return () => clearInterval(interval);
    }, [currentImage]);

    return (
        <>
            <Navbar />
            {popupVisible && (
                <div className="popup w-[90%] md:w-[50%]">
                    <div className="float-right">
                        <svg className='' xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" onClick={handleButtonClick}>
                            <rect width="24" height="24" fill="none" />
                            <g fill="none" stroke="#0A2640" stroke-width="1.5">
                                <circle cx="12" cy="12" r="10" />
                                <path stroke-linecap="round" d="m14.5 9.5l-5 5m0-5l5 5" />
                            </g>
                        </svg>
                    </div>
                    <div className='flex justify-center mt-5'>
                        <svg className='hidden md:block' xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 24 24">
                            <rect width="24" height="24" fill="none" />
                            <path fill="none" stroke="#526484" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M17 17a5 5 0 0 0-3.537-3.538M17 17a5 5 0 0 0 1.296-4.834M17 17a5 5 0 0 0 4.834-1.295M17 17a5 5 0 0 0 3.537 3.539M17 17a5 5 0 0 0-1.296 4.834M17 17a5 5 0 0 0-4.834 1.296m1.297-4.834a5.05 5.05 0 0 0-1.297 4.834m1.297-4.834a5.05 5.05 0 0 1 4.833-1.296m0 0a5.01 5.01 0 0 1 3.538 3.539m0 0a5.05 5.05 0 0 1-1.297 4.834m0 0a5.05 5.05 0 0 1-4.832 1.295m0 0a5.01 5.01 0 0 1-3.539-3.538M2.38 2.466C4.216.49 18.012 5.329 18 7.096c-.013 2.003-5.388 2.62-6.878 3.037c-.896.251-1.135.51-1.342 1.449c-.936 4.254-1.405 6.37-2.476 6.418C5.598 18.075.591 4.393 2.381 2.466" color="#526484" />
                        </svg>
                        <svg className='block md:hidden' xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 24 24">
                            <rect width="24" height="24" fill="none" />
                            <path fill="none" stroke="#526484" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2" d="M17 17a5 5 0 0 0-3.537-3.538M17 17a5 5 0 0 0 1.296-4.834M17 17a5 5 0 0 0 4.834-1.295M17 17a5 5 0 0 0 3.537 3.539M17 17a5 5 0 0 0-1.296 4.834M17 17a5 5 0 0 0-4.834 1.296m1.297-4.834a5.05 5.05 0 0 0-1.297 4.834m1.297-4.834a5.05 5.05 0 0 1 4.833-1.296m0 0a5.01 5.01 0 0 1 3.538 3.539m0 0a5.05 5.05 0 0 1-1.297 4.834m0 0a5.05 5.05 0 0 1-4.832 1.295m0 0a5.01 5.01 0 0 1-3.539-3.538M2.38 2.466C4.216.49 18.012 5.329 18 7.096c-.013 2.003-5.388 2.62-6.878 3.037c-.896.251-1.135.51-1.342 1.449c-.936 4.254-1.405 6.37-2.476 6.418C5.598 18.075.591 4.393 2.381 2.466" color="#526484" />
                        </svg>
                    </div>
                    <div className='mt-5'>
                        <p className='text-center text-[15px] md:text-[18px] text-textBlack font-medium'>We know, we are also very eager to get you started on this journey.</p>
                        <p className='text-center text-[15px] md:text-[18px] text-textBlack font-medium'>We are coming live very soon.</p>
                        <p className='text-center text-[15px] md:text-[18px] text-textBlack font-medium'>In the interim, Join our waitlist and we will connect.</p>
                        <div className='flex justify-center mt-3 mb-4'>
                            <a href={referralCode ? `/${referralCode}` : '/'}
                                className='block mt-2 bg-deepBlue btn-sm btn-dark text-textBlue'>Join now</a>
                        </div>
                    </div>
                </div>
            )}
            <div>
                <div className=' mainCTA h-[110vh] md:h-screen -mt-[150px]'>
                    <div className='relative'>
                        <div className=' m-2 w-full absolute left-0'>
                        </div>
                        {data?.country == 'CA' ? <div className=' w-full absolute left-0'>
                        </div> : <></>}
                        {data?.country == 'NG' ? <div className=' w-full absolute left-0'>
                        </div> : <></>}
                        {data?.country == 'GH' ? <div className=' w-full absolute left-0'>
                        </div> : <></>}
                        {data?.country == 'SA' ? <div className=' w-full absolute left-0'>
                        </div> : <></>}
                    </div>
                    <div className="  relative top-[100px]  px-[30px] md:px-[129px]">
                        <div className="w-full grid grid-cols-12 md:gap-20 z-50">
                            <div className="col-span-12 md:col-span-6 pt-[60px] md:pt-[120px]">
                                <h1 className="text-white">Build your dreams, change the world!</h1>
                                <p className="text-[#FFFFFF]">
                                    Innovation is the core for every new<br /> business and idea, we believe in your ability <br />
                                    to make an impact, leverage our advanced <br /> AI tools to create your vision.
                                </p>
                                <div className="flex gap-4 mt-5 md:mt-10">
                                    <a href={`https://app.craddule.com/signup/${code ? code : ''}`}
                                        className="btn btn-dark-outline bg-[#193FAE] text-white">Get Started</a>
                                </div>
                            </div>
                            <div className="col-span-12 pt-[50px] flex flex-col items-center md:col-span-6 md:pt-[70px]">
                                <div className="flex justify-center w-full">
                                    <img
                                        className="bg-opacity-100 md:w-[80%] w-[60%] "
                                        src={images[currentImage]}
                                        alt=""
                                    />
                                </div>
                                <p className="text-[#A3A3A3] text-center mt-4 md:mt-6">
                                    “Your imagination is the window into a new world, let Craddule help you build it.”
                                </p>
                            </div>

                        </div>
                    </div>

                </div>

                <div className="px-[30px] bg-gray-10 md:px-[107px] md:rounded-md md:w-[80%] m-auto py-[15px] my-10 md:my-24 bg-none">
                    <div className="w-fit m-auto">
                        <h2 className="text-center text-white mb-2 border-b-8 border-[#193FAE] inline-block rounded-b-lg rounded-t-lg mx-auto pb-2">
                            What we offer
                        </h2>
                    </div>
                    <div className="relative grid grid-cols-1 md:grid-cols-3 gap-6 items-center justify-center">
                        {/* Top Left */}
                        <motion.div
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.8 }}
                            className="flex flex-col items-center md:col-start-1 md:row-start-1"
                        >
                            <img className="w-[20px] md:w-[30px]" src={Smart} alt="" />
                            <p className="mt-2 text-[18px] md:text-[24px] font-bold text-white text-center">
                                Smart Document Creation
                            </p>
                            <p className="mt-3 text-[13px] md:text-[16px] text-textBlack text-center">
                                Create professional-grade documents with ease, powered by Abby.
                            </p>
                        </motion.div>

                        {/* Top Right */}
                        <motion.div
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.8, delay: 0.2 }}
                            className="flex flex-col items-center md:col-start-3 md:row-start-1"
                        >
                            <img className="w-[30px] md:w-[40px]" src={Financial} alt="" />
                            <p className="mt-2 text-[24px] font-bold text-white text-center">
                                Powerful Financial Insights
                            </p>
                            <p className="mt-3 text-[16px] text-textBlack text-center">
                                Track financial performance and forecast revenue.
                            </p>
                        </motion.div>

                        {/* Center (Main) */}
                        <div className="flex flex-col items-center md:col-start-2 md:row-start-2 p-4 md:p-6 rounded-lg relative">
                            <motion.img
                                initial={{ scale: 0.8, opacity: 0 }}
                                animate={{ scale: 1, opacity: 1 }}
                                transition={{ duration: 0.8 }}
                                className="w-[90%] md:w-[100%]"
                                src={middle_image}
                                alt=""
                            />

                            {/* Arrows with bounce animation */}
                            {[first_arrow, second_arrow, third_arrow, fourth_arrow].map((arrow, index) => (
                                <motion.img
                                    key={index}
                                    src={arrow}
                                    alt="Arrow"
                                    className="absolute hidden md:block w-[20px]"
                                    animate={{ y: [0, -10, 0] }}
                                    transition={{ duration: 1, repeat: Infinity, ease: "easeInOut" }}
                                    style={[
                                        { top: "-10px", left: "20px" }, // Top Left
                                        { top: "-10px", right: "20px" }, // Top Right
                                        { bottom: "-10px", left: "20px" }, // Bottom Left
                                        { bottom: "-10px", right: "5px" } // Bottom Right
                                    ][index]}
                                />
                            ))}
                        </div>

                        {/* Bottom Left */}
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.8, delay: 0.4 }}
                            className="flex flex-col items-center md:col-start-1 md:row-start-3"
                        >
                            <img className="w-[30px]" src={Pitch} alt="" />
                            <p className="mt-2 text-[24px] font-bold text-white text-center">
                                Investor-Ready Pitch Decks
                            </p>
                            <p className="mt-3 text-[16px] text-textBlack text-center">
                                Craft compelling pitch decks with expert guidance.
                            </p>
                        </motion.div>

                        {/* Bottom Right */}
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.8, delay: 0.6 }}
                            className="flex flex-col items-center md:col-start-3 md:row-start-3"
                        >
                            <img className="w-[30px]" src={Seamless} alt="" />
                            <p className="mt-2 text-[28px] font-bold text-white text-center">
                                Seamless Collaboration
                            </p>
                            <p className="mt-3 text-[16px] text-textBlack text-center">
                                Assign tasks and track progress in real-time.
                            </p>
                        </motion.div>
                    </div>
                </div>

                <div className='px-[30px] md:px-[107px] py-[15px] text-white my-10 md:my-24 bg-none'>
                    <div className='grid grid-cols-12 md:gap-20 items-start'>
                        <div className='col-span-12 md:col-span-6'>
                            <div className=''>
                                <h2 className='text-white' >We connect our customers with the best features.</h2>
                                <div className='flex gap-2 md:gap-3 items-center mt-5 md:mt-10'>
                                    <img className='w-[20px] h-[20px] md:w-[30px] md:h-[30px]' src={Check} alt="" />
                                    <p className='text-[14px] md:text-[16px]'>A comprehensive solution that guides and supports clients through the entire ideation-to-execution process.</p>
                                </div>
                                <div className='flex gap-2 md:gap-3 items-center mt-5 md:mt-10'>
                                    <img className='w-[20px] h-[20px] md:w-[30px] md:h-[30px]' src={Check} alt="" />
                                    <p className='text-[14px] md:text-[16px]'>We collaborate with our clients, offering expert guidance and leveraging our extensive network.</p>
                                </div>
                                <div className='flex gap-2 md:gap-3 items-center mt-5 md:mt-10'>
                                    <img className='w-[20px] h-[20px] md:w-[30px] md:h-[30px]' src={Check} alt="" />
                                    <p className='text-[14px] md:text-[16px]'>We provide the vital tools and support needed to transform concepts into impactful solutions.</p>
                                </div>
                            </div>
                            <a href={`https://app.craddule.com/signup/${code ? code : ''}`} className='block btn btn-dark mt-5 md:mt-10'>Get Started</a>
                        </div>
                        <div className='col-span-12 md:col-span-6'>
                            <div className=''>
                                <img className='m-auto w-[300.6px] h-[350px] md:w-[582.6px] md:h-[560px]' src={imagestwo[currentImage]} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />

            </div>
        </>
    )
};

export default Home;
