import BuildCTA from '../components/buildCTA';
import Subscribe from '../components/subscribe';
import Accelera from '../assets/images/accelera.svg'
import Hub from '../assets/images/clouse.svg'
import Builder from '../assets/images/timebuiled.svg'
import Chat from '../assets/images/chat.svg'
import KPI from '../assets/images/tracking.svg'
import Graphs from '../assets/images/set.svg'
import '../input.css'
import Footer from '../components/footer';
import Navbar from '../components/navbar';
import ReactGA from "react-ga4";
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const Features = () => {
    ReactGA.initialize("G-125ZTWLY25");
    ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname,
        title: "Features"
    });

    const navigate = useNavigate();
    const { referralCode } = useParams()

    useEffect(() => {
        if (referralCode) {
            localStorage.setItem('referralCode', referralCode);
        }
    }, [referralCode]);
    return (
        <>
            <Navbar />
            <div className='px-[30px] md:px-[107px] py-[15px] mt-10 md:my-24 bg-none'>
                <div className='w-fit m-auto' >
                    <h2 className='text-center text-white border-b-8 border-[#193FAE] inline-block rounded-b-lg rounded-t-lg mx-auto  mb-10 md:mb-20'>Our Features</h2>
                </div>

                <div className="grid grid-cols-12 gap-5 items-start">
                    {[
                        { img: Accelera, title: "Accelera8", desc: "Save time and launch your product just in time to infiltrate the market with no compromises on our service or your product quality." },
                        { img: Hub, title: "Craddule hub", desc: "Store, categorize, and access your project documentation effortlessly. No more frantic searches for that crucial file—organize, access, and collaborate with ease." },
                        { img: Builder, title: "Timeline Builder", desc: "Schedule, assign, and track tasks seamlessly, ensuring that your project stays on course and deadlines are met with ease. Say goodbye to missed timelines and hello to streamlined productivity." },
                        { img: Chat, title: "Chat", desc: "Our communication tool keeps everyone on the same page. No more communication gaps—just smooth, transparent, and effective team communication." },
                        { img: KPI, title: "KPI", desc: "Tracking your progress has never been made easier, see your performance indicators displayed as graphs for easy understanding." },
                        { img: Graphs, title: "Graphs", desc: "Set, monitor, and manage your budgets effectively for different project stages. Stay within your financial boundaries while maximizing the impact of your resources." },
                    ].map(({ img, title, desc }, index) => (
                        <div key={index} className="card flex flex-col text-[#D1D1D1] bg-gray-10 p-5 rounded-lg col-span-12 md:col-span-4 h-full">
                            <img className="m-auto" src={img} alt={title} />
                            <p className="mt-4 text-[20px] md:text-[28px] font-bold text-white text-center">{title}</p>
                            <p className="mt-1 md:mt-5 text-[13px] md:text-[16px] text-[#D1D1D1] text-center flex-grow">{desc}</p>
                        </div>
                    ))}
                </div>

            </div>

            <BuildCTA />
            <Footer />
        </>
    )
};

export default Features;