import BuildCTA from '../components/buildCTA';
import Innovation from '../assets/images/innovation.png'
import Inclusion from '../assets/images/inclusion.png'
import Collaboration from '../assets/images/collaboration.png'
import Excellence from '../assets/images/excellence.png'
import Hands from '../assets/images/who.png';
import Vision from '../assets/images/vision.png';
import '../input.css'
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import ReactGA from "react-ga4";
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
const About = () => {
     
  ReactGA.initialize("G-125ZTWLY25");
  ReactGA.send({ 
   hitType: "pageview", 
   page: window.location.pathname, 
   title: "About Us" 
 });
    const navigate = useNavigate();
    const [referralCode, setReferralCode] = useState('');

    useEffect(() => {
        // Fetch referralCode from localStorage
        const storedReferralCode = localStorage.getItem('referralCode');
        if (storedReferralCode) {
            setReferralCode(storedReferralCode);
        }
    }, []);
    return(
        <>
            <Navbar/>
            <div className='px-[30px] bg-gray-10 md:w-[95%] m-auto p-5 rounded-lg md:px-[139px] md:py-[15px] my-10 md:my-24 bg-none'>
                <div className='grid md:gap-20 grid-cols-12'>
                    <div className='col-span-12 mb-10 md:mb-0 md:col-span-7'>
                        <p className='text-white font-bold'>Our story</p>
                        <h2 className='mb-2 md:mb-4 text-white border-b-8 border-[#193FAE] inline-block rounded-b-lg rounded-t-lg mx-auto '>Your Launchpad to Success </h2>
                        <p className='text-[14px] md:text-[16px] text-[#FFFFFF]'>
                            Craddule is your ultimate toolkit for turning ideas into reality. Whether you're starting fresh or scaling up, we’ve got the tools to streamline your journey effortlessly.

                        </p>
                    </div>
                    <div className='col-span-12 md:col-span-5'>
                        <img className='m-auto w-[350px] rounded-md' src={Hands} alt="" />
                    </div>
                </div>
                <div className='grid md:gap-20 grid-cols-12 mt-10 mb:mt-48'>
                    <div className='hidden md:block md:col-span-7'>
                        <img className='m-auto mt-10 w-[500px] rounded-md' src={Vision} alt="" />
                    </div>
                    <div className='col-span-12 md:col-span-5'>
                        <h2 className='mb-2 md:mb-4 text-white border-b-8 border-[#193FAE] inline-block rounded-b-lg rounded-t-lg mx-auto '>Our Mission</h2>
                        <p className='text-[14px] text-[#FFFFFF] md:text-[16px]'>
                            Our mission is simple: to help startups succeed. At Craddule, 
                            we believe that innovation and entrepreneurship are the driving forces of progress, we understand the unique challenges that new ventures face and offer tailored solutions to meet these challenges head-on. Whether it's refining your business model, securing funding, or crafting a go-to-market strategy, Craddule is here to guide you every step of the way. 
                        </p>
                    </div>
                    <div className='col-span-12 md:col-span-7 md:hidden mt-7 md:mt-0'>
                        <img className='m-auto w-[350px] rounded-md' src={Vision} alt="" />
                    </div>
                </div>
            </div>

            <div className=' text-[#FFFFFF]  px-[30px] md:px-[139px] py-[15px] my-10 md:my-24 bg-none'>
            <h2 className='mb-8 md:mb-16 text-white border-b-8 border-[#193FAE] inline-block rounded-b-lg rounded-t-lg mx-auto'>Our Values</h2>
                <p className='text-[16px] md:text-[24px] -mt-8 mb:-mt-16 text-[#FFFFFF] '>At Craddule, we're passionate about</p>
                <div className="mt-5 md:mt-12">
                    <div className="flex flex-wrap md:flex-nowrap gap-4">
                        <div className="md:w-1/2 w-full bg-gray-10 p-5 rounded-lg">
                            <div className="flex gap-4 md:gap-6 items-center">
                                <img className="w-[50px] h-[50px] md:w-auto md:h-auto" src={Innovation} alt="Innovation" />
                                <div>
                                    <p className="text-lg md:text-2xl text-white font-semibold">Innovation</p>
                                    <p className="text-sm md:text-lg text-gray-300">We embrace creativity and outside-the-box thinking.</p>
                                </div>
                            </div>
                        </div>
                        <div className="md:w-1/2 w-full bg-gray-10 p-5 rounded-lg">
                            <div className="flex gap-4 md:gap-6 items-center">
                                <img className="w-[50px] h-[50px] md:w-auto md:h-auto" src={Inclusion} alt="Inclusion" />
                                <div>
                                    <p className="text-lg md:text-2xl text-white font-semibold">Inclusion</p>
                                    <p className="text-sm md:text-lg text-gray-300">We support diversity and equal opportunities for all.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap md:flex-nowrap gap-4 mt-4 md:mt-8">
                        <div className="md:w-1/2 w-full bg-gray-10 p-5 rounded-lg">
                            <div className="flex gap-4 md:gap-6 items-center">
                                <img className="w-[50px] h-[50px] md:w-auto md:h-auto" src={Collaboration} alt="Collaboration" />
                                <div>
                                    <p className="text-lg md:text-2xl text-white font-semibold">Collaboration</p>
                                    <p className="text-sm md:text-lg text-gray-300">We believe that together, we can achieve more.</p>
                                </div>
                            </div>
                        </div>
                        <div className="md:w-1/2 w-full bg-gray-10 p-5 rounded-lg">
                            <div className="flex gap-4 md:gap-6 items-center">
                                <img className="w-[50px] h-[50px] md:w-auto md:h-auto" src={Excellence} alt="Excellence" />
                                <div>
                                    <p className="text-lg md:text-2xl text-white font-semibold">Excellence</p>
                                    <p className="text-sm md:text-lg text-gray-300">We strive for exceptional results and continuous improvement.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <BuildCTA/>
            <div className='px-[30px] md:px-[139px] py-[15px] my-10 md:my-24 bg-none'>
                <h2 className='mb-5 md:mb-16 text-white border-b-8 border-[#193FAE] inline-block rounded-b-lg rounded-t-lg mx-auto  '>Additional Considerations</h2>
                <div className='fle-wrap md:flex gap-4'>
                    <div className='w-full md:w-1/2'>
                        <h4 className='text-white' >Custom Features/Add-ons</h4>
                        <p className='text-[#FFFFFF]'>Offer additional features or modules as add-ons for users who need specialized functionalities beyond their subscribed tier.</p>
                    </div>
                    <div className='w-full mt-3 md:mt-0 md:w-1/2'>
                        <h4 className='text-white' >Feedback-Driven Improvements</h4>
                        <p className='text-[#FFFFFF]'>Feedback is only given by industry professionals.</p>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
};

export default About;