import '../input.css'
import ContactImg from '../assets/images/contact.svg'
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import ReactGA from "react-ga4";
import { API_BASE_URL } from '../config/apiConfig';
const Contact = () => {
    ReactGA.initialize("G-125ZTWLY25");
    ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname,
        title: "Contact Us"
    });

    const navigate = useNavigate();
    const { referralCode } = useParams()

    useEffect(() => {
        if (referralCode) {
            localStorage.setItem('referralCode', referralCode);
        }
    }, [referralCode]);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [loading, setLoading] = useState(false);
    const [responseMessage, setResponseMessage] = useState(null);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setResponseMessage(null);

        try {
            const res = await fetch(`${API_BASE_URL}/api/mail/contact-us`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });

            const data = await res.json();
            if (data.success) {
                setResponseMessage('Message sent successfully!');
                setFormData({ name: '', email: '', message: '' });
            } else {
                setResponseMessage('Failed to send message. Try again.');
            }
        } catch (error) {
            console.error('Error:', error);
            setResponseMessage('An error occurred.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Navbar />
            <div className='flex justify-between gap-20 items-start px-[30px] md:px-[139px] md:py-[15px] my-10 md:my-24'>
                <div className='hidden md:block contact-img w-1/2 rounded-2xl'>
                    <img src={ContactImg} alt="" />
                </div>
                <div className='m-auto w-[95%] p-5 rounded-lg bg-gray-10 md:w-1/2 text-[#D1D1D1]'>
                    <span className='text-[12px] md:text-[14px]'>Please leave a message</span>
                    <h3 className='text-[30px] text-[#B8C5EB] -mt-2 md:-mt-0'>Contact Us</h3>
                    <form onSubmit={handleSubmit} className='mt-3 md:mt-8'>
                        <div>
                            <span className='font-[700] text-[14px]'>Name</span>
                            <input
                                className='w-full border border-[#A3A3A3] rounded-lg bg-black/30 text-[16px] p-3'
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                placeholder='Full name'
                                required
                            />
                        </div>
                        <div className='mt-5'>
                            <span className='font-[700] text-[14px]'>Email</span>
                            <input
                                className='w-full border border-[#A3A3A3] rounded-lg bg-black/50 text-[16px] p-3'
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder='Email address'
                                required
                            />
                        </div>
                        <div className='mt-5'>
                            <span className='font-[700] text-[14px]'>Message</span>
                            <textarea
                                className='w-full h-[100px] border border-[#A3A3A3] rounded-lg bg-black/50 text-[16px] p-3'
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                placeholder='Type your message...'
                                required
                            />
                        </div>
                        <div className='mt-5 flex items-center'>
                            <input className='bg-check-back' type="checkbox" required />
                            <span className='pl-2 text-[14px] text-[#D1D1D1]'>I accept the Terms & Conditions</span>
                        </div>
                        <div className='mt-6'>
                            <button type="submit" className='btn btn-dark' disabled={loading}>
                                {loading ? 'Sending...' : 'Submit'}
                            </button>
                        </div>
                        {responseMessage && <p className="mt-4 text-center">{responseMessage}</p>}
                    </form>
                </div>
            </div>
            <Footer />
        </>
    )
};

export default Contact;